@use "sass:math";
@use 'variables' as v;
@use 'mixins' as m;

.wrapper {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    @include m.tablet{
        width: v.$main_width;
    }
}

.pGreen{
    margin-top: 3rem;
    color: v.$font_color--4;
    font-size: 2.6rem;
    font-weight: bold;
}

.pGreen--light{
    color: v.$font_color--2;
    font-size: 1.8rem;
    font-weight: bold;
}

.pBlack--small{
    font-size: 1.8rem;
    font-weight: bold;
}

.mTop-3{
    margin-top: 3rem;
}

.mTop-5{
    margin-top: 5rem;
}

.pBold{
    font-weight: bold;
}

.tCenter{
    text-align: center;
}

.image--small{
    width: 50%;
    margin: 2.5rem auto;
}