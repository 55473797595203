@use 'sass:math';
@use 'base/variables' as v;
@use 'base/mixins' as m;

.gallery{
    max-width: 85%;
    position: relative;
    margin: 5rem auto;
    &__slides{
        display: none;
        &__img{
            vertical-align: middle;
            width: 100%;
            height: auto;
            margin: auto;
            @include m.bigScreen{
                width: auto;
                height: 100%;
                max-height: 55rem;
            }
        }
    }
    &__controls{
        text-align: center;
    }
    &__dot{
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: v.$brand_color_disabled;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
        &__active, &:hover{
            background-color: v.$brand_color;
            cursor: pointer;
        }
    }
}