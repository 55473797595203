@use 'sass:math';
@use 'base/variables' as v;
@use 'base/mixins' as m;

.hotelero{
    margin-top: 5rem;
    li{
        list-style-image: url('../img/check.webp');
    }
}

.grid-hotelero{
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    img{
        width: 28rem;
    }
}