@use 'sass:math';
@use 'base/variables' as v;
@use 'base/mixins' as m;

.modal{
    width: 85%;
    height: auto;
    background-color: v.$background_color;
    &__overlay{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.9);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__close-btn{
        font-size: 3rem;
        position: absolute;
        top: 5%;
        right: 5%;
        background-color: v.$brand_color;
        color: v.$background_color;
        border-radius: 50%;
        padding: 0.5rem 1rem;
        cursor: pointer;
        z-index: 101;
    }
}