@use "sass:math";
@use 'base/variables' as v;
@use 'base/mixins' as m;

.footer{
    background-color: v.$footer_background;
    height: v.$footer_height;
    padding: calc(v.$gap / 3) 0;
    color: v.$footer_color;
    @include m.tablet{
        padding: v.$gap;
    }
    .footer__button{
        background-color: v.$footer_button;
        color: v.$footer_button_text;
        &:hover{
            background-color: v.$footer_button_hover;
        }
        &:active{
            background-color: v.$footer_button_active;
        }
        &:disabled{
            background-color: v.$footer_button_disabled;
        }
    }
    &__grid{
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        width: 95%;
        margin: auto;
        @include m.tablet{
            width: 100%;
            grid-template-columns: auto auto;
            place-items: flex-start;
            gap: 3rem;
        }
        @include m.desktop{
            grid-template-columns: auto auto auto;
            place-items: unset;
            gap: 0rem;
        }
        &__logo{
            max-width: 15rem;
            @include m.tablet{
                max-width: 16rem;
            }
            @include m.desktop{
                max-width: 20rem;
            }
        }
        &__info{
            margin: calc(v.$gap / 2) 0;
            @include m.desktop{
                border-right: v.$border-width v.$border-style v.$border-color;
            }
            &--bloque{
                text-align: center;
                @include m.tablet{
                    text-align: left;
                }
            }
            &--title{
                font-size: 2.2rem;
                font-weight: 700;
                margin-bottom: 1rem;
                text-transform: uppercase;
                text-align: center;
                @include m.tablet{
                    font-size: 2.6rem;
                    text-align: left;
                }
                @include m.desktop{
                    font-size: 3rem;
                }
            }
            &--text{
                font-size: 1.4rem;
                margin: .5rem 0;
                color: v.$font_color--1;
                text-align: center;
                @include m.tablet{
                    font-size: 1.6rem;
                    text-align: left;
                }
                @include m.desktop{
                    font-size: 1.8rem;
                }
            }
            &--sede{
                font-size: 1.6rem;
                margin: 0;
                color: v.$font_color--1;
                font-weight: v.$thin;
                span{
                    text-transform: uppercase   ;
                    font-weight: v.$semibold;
                }
            }
            &--address{
                @include m.grid(1, 1rem);
                place-items: center;
                margin: 0;
                @include m.tablet{
                    margin: calc(v.$gap / 2) 0;
                    @include m.grid(3, 3.5rem);
                    place-items: flex-start;
                }
                @include m.desktop{
                    margin: calc(v.$gap + 2.5rem) 0;
                }
            }
        }
        &__social{
            display:flex;
            flex-direction: column;
            width: 100%;
            gap: 1rem;
            padding: 1.5rem;
            place-items: center;
            @include m.tablet{
                grid-column: 1/3;
            }
            @include m.desktop{
                padding: 2.5rem 0 0 2.5rem;
                align-items: flex-start;
                grid-column: 3/4;
            }
            &--media{
                display: flex;
                gap: 2rem;
                i{
                    color: v.$font_color--1;
                    font-size: 3rem;
                }
            }
            &--link{
                color: v.$font_color--1;
            }
            &--copy{
                color: v.$font_color--1;
                font-size: 1.4rem;
                margin: 0;
                &:first-of-type{
                    margin-top: 2rem;
                }
            }
        }
    }
    &__links{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        margin-top: 2rem;
        @include m.tablet{
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-top: 2rem;
        }
        &__link{
            @include m.boton(v.$footer_button, v.$footer_button_text, inline-block, v.$border_radius, v.$regular);
            padding: 1rem 2rem !important;
            width: 85%;
            &::first-letter{
                text-transform: uppercase;
            }
            @include m.desktop{
                min-width: 45%;
                width: auto;
            }
            @include m.bigScreen{
                min-width: unset;
            }
        }
    }
}