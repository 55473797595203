@use "sass:math";
@use 'base/variables' as v;
@use 'base/mixins' as m;

.header{
    position: relative;
    &__overlay{
        width: 100%;
        height: 100%;
        background: v.$header_overlay;
        position: absolute;
        z-index: -1;
        &__video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: overlay;
        }
        &__img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
        &__title{
            position: absolute;
            bottom: 12%;
            left: 7%;
            text-transform: uppercase;
            color: v.$header_title;
            font-weight: v.$header_title_weight;
            font-size: 3.6rem;
            z-index: -1;
            max-width: 60%;
            @include m.tablet{
                font-size: 4.5rem;
            }
            @include m.desktop{
                font-size: 5rem;
            }
        }
        &__btn{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 250%);
        }
    }
    &__index{
        height: 70vh;
        position: relative;
        &::after{
            content: url('../img/logo.webp');
            width: 100%;
            max-width: 30rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-57%, -70%);
        }
    }

    &__pages{
        background-color: v.$header_background--1;
        padding-bottom: 10rem;
    }

    &__sections{
        position: relative;
        min-height: 26rem;
        background-blend-mode: overlay;
        background-image: v.$header_overlay;
        @include m.tablet{
            min-height: 36rem;
        }
        @include m.desktop{
            min-height: 42rem;
        }
    }

    &__nav{
        z-index: 1;
        background-color: v.$brand_color;
        height: auto;
        position: relative;
        width: 100%;
        ul{
            list-style: none;
            justify-content: space-around;
            height: auto;
            align-items: center;
        }
        a{
            color: white;
            text-decoration: none;
            font-size: 1.6rem;
        }
        ul{
            padding: 4rem 0 2rem 0;
        }
        li{
            padding: 1rem 1.5rem;
            &:hover{
                cursor: pointer;
            }
            &:not(:last-child):hover{
                background-color: v.$header_button--1_hover;
            }
            @include m.tablet{
                &:not(:last-child):hover{
                    background-color: transparent;
                }
            }
            a{
                width: 100%;
                display: block;
            }
        }
        &--btn{
            background-color: v.$header_button--1;
            color: v.$header_button--1_text;
            border-radius: v.$header_button_radius;
            padding: 1rem 3rem;
            text-align: center;
            display: inline-block;
            font-weight: v.$bold;
            transition: all 0.3s ease;
            &:hover{
                background-color: v.$header_button--1_hover;
            }
            &:active{
                background-color: v.$header_button--1_active;
            }
            &:disabled{
                background-color: v.$header_button--1_disabled;
            }
        }
        &--menu{
            position: absolute;
            color: v.$header_button--1_text;
            top: 1rem;
            right: 1.2rem;
            font-size: 3.2rem;
            z-index: 11;
            font-weight: v.$bold !important;
            &:hover{
                cursor: pointer;
                color: v.$header_button--1;
                font-weight: v.$bold !important;
            }
        }
        &--mobile{
            display: none;
        }
        @include m.tablet{
            position: fixed;
            background-color: transparent;
            width: 100%;
            ul{
                display: flex;
                justify-content: space-around;
                height: 8.1rem;
                align-items: center;
            }
            a{
                color: white;
                text-decoration: none;
                font-size: 1.6rem;
            }
            ul li{
                padding: 0;
            }
            &--btn{
                background-color: v.$header_button--1;
                color: v.$header_button--1_text;
                border-radius: v.$header_button_radius;
                padding: 1rem 3rem;
                text-align: center;
                display: inline-block;
                font-weight: v.$bold;
                transition: all 0.3s ease;
                &:hover{
                    background-color: v.$header_button--1_hover;
                }
                &:active{
                    background-color: v.$header_button--1_active;
                }
                &:disabled{
                    background-color: v.$header_button--1_disabled;
                }
            }
            &--menu{
                display: none !important;
            }
        }
        @include m.desktop{
            a{
                font-size: 1.8rem;
            }
        }
        @include m.bigScreen{
            a{
                font-size: 2rem;
            }
        }
        @include m.ultraWide{
            a{
                font-size: 2.2rem;
            }
        }
    }
}

@media (max-height: 740px) {
    .header{
        &__index{
            height: 85vh;
        }
    }
}
