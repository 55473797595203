@use 'sass:math';
@use 'base/variables' as v;
@use 'base/mixins' as m;

.grid-especializados{
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    img{
        max-width: 40%;
    }
}