@use 'sass:math';
@use 'base/variables' as v;
@use 'base/mixins' as m;

.banner__trabajo{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 10rem 0;
    &--texto{
        background: linear-gradient(180deg, #039E00 0%, #02B665 100%);
        height: 100%;
        color: v.$font_color--1;
        display: grid;
        place-items: center;
        padding: 5rem;
        p{
            color: v.$font_color--1;
            text-align: center;
        }
    }
    &--contacto{
        background-color: v.$brand_color--3;
        width: 80%;
        padding: 1.5rem;
        border-radius: v.$border-radius;
    }
}