@use "sass:math";
@use 'base/variables' as v;
@use 'base/mixins' as m;

.contacto{
    background-color: v.$fixed_section_background;
    min-height: v.$fixed_section_height;
    padding: 5rem 0;
    &__grid{
        display: grid;
        @include m.grid(1, 0);
        justify-content: center;
        align-items: center;
        height: 100%;
        @include m.desktop{
            @include m.grid(2, 0);
        }
    }
    &__form{
        background-color: v.$form_background;
        border-radius: v.$border_radius;
        padding: 1.5rem;
        width: 100%;
        margin: auto;
        @include m.tablet{
            width: 75%;
        }
        @include m.desktop{
            width: 40rem;
        }
        &__img{
            width: auto;
            height: 6rem;
            display: block;
            margin: auto;
            @include m.desktop{
                height: 10rem;
            }
        }
        &__legend{
            display: none;
        }
        &__fieldset{
            border: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 0.1rem;
            label{
                visibility: hidden;
            }
            input:not([type='submit']), textarea{
                background-color: v.$input_background;
                border: v.$input_border_width v.$input_border_style v.$input_border;
                border-radius: v.$input_border_radius;
                padding: 1rem;
                color: v.$input_text;
                font-size: v.$input_text_size;
                font-weight: v.$input_text_weight;
                min-width: v.$input_width;
                &:focus{
                    outline: none;
                    border-color: v.$input_submit;
                }
            }
            textarea{
                resize: none;
                min-height: 10rem;
            }
        }
        &__btn{
            margin-top: 1.5rem;
            background-color: v.$input_submit;
            color: v.$input_submit_text;
            border: none;
            font-size: 1.6rem;
            padding: 1rem;
            border-radius: v.$border_radius;
            display: block;
            cursor: pointer;
            transition: all 0.3s ease;
            @include m.desktop{
                font-size: 2.2rem;
            }
            &:hover{
                background-color: v.$input_submit_hover;
            }
            &:active{
                background-color: v.$input_submit_active;
            }
            &:disabled{
                background-color: v.$input_submit_disabled;
            }
        }
    }
    &__text{
        display: flex;
        flex-direction: column;
        grid-row: 1 / 2;
        text-align: center;
        @include m.desktop{
            grid-column: 2 / 3 ;
            text-align: left;
            gap: 2rem;
        }
        &__title{
            font-size: 3.2rem;
            font-weight: v.$fixed_section_title_weight;
            color: v.$fixed_section_title;
            @include m.desktop{
                font-size: v.$fixed_section_title_size;
            }
        }
        &__p{
            color: v.$fixed_section_text;
        }
    }
}

.contacto__top{
    .contacto__form{
        width: auto;
        max-width: 38rem;
        margin-bottom: v.$gap;
        @include m.tablet{
            transform: translate(0, -15rem);
            margin-bottom: 0;
        }
        @include m.desktop{
            transform: translate(0, -25rem);
        }
    }
    .contacto__form--shadow{
        box-shadow: v.$form_shadow
    }

    .contacto__form__legend{
        display: block;
        color: v.$form_title;
        font-size: 2.2rem;
        font-weight: v.$form_title_weight;
    }
}